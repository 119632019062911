/* Define Local Variables */

:root {
  --tooltip-var: #ff6961;
}

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 20px;
  top: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.5em !important;
  z-index: 2000;
  animation: appear 0.5s ease-in-out forwards;
}

.alert-body {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.alert p {
  margin: 0;
}
.alert i {
  font-size: 1em;
  cursor: pointer;
  margin-right: 10px;
}

.alert-error {
  color: var(--white);
  background-color: var(--danger);
  margin-top: 10px;
}

.alert-success {
  color: var(--bg-primary);
  background-color: var(--success);
  margin-top: 10px;
}

.alert-warning {
  color: var(--bg-primary);
  background-color: var(--warning);
  margin-top: 10px;
}

.alert-info {
  color: var(--bg-primary);
  background-color: var(--info);
  margin-top: 10px;
}

.tooltip-container {
  width: auto;
  text-transform: uppercase;
  cursor: help !important;
  font-family: "Gill Sans", Impact, sans-serif;
  position: relative;
  text-align: center;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
  z-index: 1;
}

.tooltip-container .tooltip {
  background: var(--bg-primary);
  bottom: 100%;
  display: block;
  margin: 0 auto;
  white-space: nowrap;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: fit-content;
  font-size: 1.5rem;
  transform: translateY(10px);

  z-index: 1000;
}

.tooltip-container .tooltip:after {
  border-left: solid transparent 10px !important;
  border-right: solid transparent 10px !important;
  border-top: solid var(--primary) 10px;
  bottom: -10px;
  content: " ";
  left: 50%;
  margin-left: -13px;
  position: absolute;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  animation: appear 0.3s ease-in-out forwards;
}

.tooltip-container .tooltip.down {
  bottom: -50%;
}

.tooltip-container .tooltip.down:after {
  border-left: solid transparent 10px !important;
  border-right: solid transparent 10px !important;
  border-top: solid transparent 10px !important;
  bottom: auto;
  top: -20px;
}

.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

.alert .click-me {
  cursor: pointer;
  position: absolute;
  font-size: 0.5em;
  right: 0px;
  top: 0px;
}

.alert.clickable {
  cursor: pointer;
}

.alert.clickable:hover {
  scale: 1.02;
}

@media screen and (max-width: 768px) {
  .alert {
    right: 10px;
    font-size: 1em !important;
  }
}
