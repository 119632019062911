.ac-main {
  height: var(--main-height);
  background-color: var(--bg-primary) !important;
  overflow: hidden;
}

.ac-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.ac-container.column {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-top: -3rem;
}

.ac-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  aspect-ratio: 1/1;
  overflow: hidden;
  margin-left: 2%;
}

.ac-right {
  top: 10%;
  right: 0;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out normal forwards;
}

.ac-span {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2%;
  color: var(--primary);
}

.ac-content h1 {
  font-weight: 200;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: var(--primary);
}

.ac-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px auto;
  margin-top: 50px;
  width: 20%;
  aspect-ratio: 1/1;
}

.ac-bottom {
  width: 90%;
}

.ac-button {
  font-size: 2rem;
  position: absolute;
  bottom: 5%;
  right: 3%;
  width: 100px;
}

.ac-input {
  width: 50%;
}

.ac-input input {
  width: 100%;
  height: 100%;
  font-size: 1.8em;
  border: 1px solid var(--primary);
  border-radius: 5px;
  background-color: var(--bg-primary);
  color: var(--primary);
}

.ac-input label {
  font-size: 1em !important;
}

@keyframes moveUp {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(100px);
  }
  90% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(-500px);
    visibility: hidden;
  }
}

.typewriter {
  margin: 0 auto;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
}
.typewriter h1 {
  font-family: monospace;
  font-weight: 100;
  font-size: 3em;
  color: var(--primary);
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(255, 255, 255); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.3em; /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

.glow {
  animation: borderGlow 1s infinite;
}

@keyframes borderGlow {
  0% {
    box-shadow: 0 0 0 0 var(--blue);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .ac-container {
    width: 100%;
    justify-content: flex-start;
    padding-top: 0px;
  }

  .ac-container-2 {
    width: 100%;
  }
  .ac-container-2 > div {
    width: 100%;
  }
  .ac {
    width: 200% !important;
    height: 100% !important;
    top: 0%;
    left: -50%;
  }
  .ac-top {
    font-size: 3em;
    padding: 40px;
    width: 60%;
  }

  .ac-bottom {
    margin-top: 0%;
  }

  .ac-input {
    width: 100%;
    font-size: 3em;
  }

  .ac-main {
    top: 30%;
    left: 0;
    font-size: 1vw;
  }
  .ac-main > div {
    width: 100%;
    height: 100%;
  }
  .typewriter {
    margin: 0 auto;
    width: fit-content;
    height: 50px;
    font-size: 1.3em;
  }

  .typewriter h1 {
    font-size: 3em;
    letter-spacing: 0.1em;
  }

  .ac-content {
    top: 20%;
    width: 90%;
    margin: 0 auto;
  }
  .ac-content h1 {
    font-size: 2rem;
  }
  .ac-span {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .ac-span > input {
    width: 95%;
    margin: 0 auto;
  }
  .ac-2-span > i {
    margin: 0 auto;
    font-size: 5vw;
  }
}
