.schedule-container {
  background-color: var(--bg-primary);
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  width: 90%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  /* animation: slideInRight 0.5s ease-in-out normal forwards; */
}

.schedule-appointment-info {
  border: 2px solid var(--ash);
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5em;
  transition: all 0.3s ease-in-out;
}

.schedule-appointment-info.not-selected h1 {
  animation: width-grow-90 0.5s ease-in-out;
  width: 90%;
}
.schedule-appointment-info.not-selected h2 {
  animation: width-shrink-0 0.5s ease-in-out;
  width: 0px;
}

.schedule-appointment-info.selected h1 {
  animation: width-shrink-30 0.5s ease-in-out;
  width: 30%;
}
.schedule-appointment-info.selected h2 {
  animation: width-grow-60 0.5s ease-in-out;
  width: 60%;
}

.schedule-container span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-weight: bold;
  width: 100%;
  height: 17%;
}

.schedule-container span > h1 {
  min-height: 30px;
  max-height: 50px;
  height: 50%;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary);
  background-color: var(--bg-secondary);
  border: 2px solid var(--primary);
  border-left: none;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.schedule-container span > h2 {
  min-height: 30px;
  max-height: 50px;
  color: var(--ash);
  font-size: 1.6em;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  background-color: var(--bg-secondary);
  border: 2px solid var(--primary);
  border-right: none;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.schedule-header {
  display: flex;
  align-items: center;
  color: var(--ash);
  padding-left: 2%;
  font-size: 1.5em;
  transition: all 0.3s ease-in-out;
  border: 2px solid var(--ash);
  height: 50px;
  border-radius: 5px 5px 0px 0px;
}

.schedule-header h2 {
  font-size: 100%;
  margin-left: 2%;
  padding-left: 10px;
  width: 100%;
  border: none;
  color: var(--bg-primary);
  background-color: var(--ash);
  height: 100%;
  display: flex;
  align-items: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.schedule-container footer {
  width: 100%;
}

.book-appointment {
  position: relative;
  justify-content: center;
  background-color: var(--bg-primary) !important;
  border: 3px solid var(--primary);
  width: 100%;
  font-size: 1.4em;
  font-weight: 800;
  color: var(--primary);
  display: flex;
  align-items: center;
  margin: 0px auto;
  border-radius: 4px;
}

.book-appointment i {
  position: absolute;
  right: 10px;
}

.book-appointment:hover {
  background-color: var(--primary) !important;
  transition: all 0.3s ease-in-out;
  font-size: 1.5em;
  color: var(--bg-primary);
}

/* MOBILE STYLING  */
.service-dropdown {
  width: 99%;
  border: 2px solid var(--primary);
}

.service-dropdown.dropdown-menu {
  top: 100%;
  border: 2px solid var(--primary);
}

.service-dropdown.dropdown-menu::after {
  display: none;
}

@media screen and (max-width: 1500px) {
  .schedule-body {
    margin-bottom: 0px;
    font-size: 1.3em;
    padding: 0px;
  }
  .schedule-container {
    padding: 0px;
    width: 100%;
    font-size: 0.8em !important;
  }

  .schedule-appointment-info {
    border: none;
  }

  .schedule-header {
    width: 100%;
    border: none;
    border-bottom: 2px solid var(--ash);
  }

  .book-appointment {
    border: none;
    border-top: 2px solid var(--ash);
    border-radius: 0px;
  }
}
