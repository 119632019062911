.dynamic-container {
  display: flex;
  min-width: 250px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: var(--bg-primary);
  border: 3px solid var(--ash);
  border-radius: 4px;
  overflow: hidden;
  font-size: 1em;
  /* animation: slideInRight 0.5s ease-in-out normal forwards; */
}

.dynamic-header {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--primary);
  width: 100%;
  font-size: 1.3em;
  border-bottom: 3px solid var(--ash);
  margin-top: 0px;
}
.dynamic-header > h1 {
  text-transform: uppercase;
  text-align: center;
  width: 80%;
}
.dynamic-header-icon-left {
  position: absolute;
  left: 10px;
  font-size: 1.5em;
}
.dynamic-header-icon-right {
  position: absolute;
  right: 10px;
  font-size: 1.5em;
}

.dynamic-container.mobile {
  width: 98%;
  height: 99%;
  margin: 0px 1px;
  position: fixed;
  bottom: 0px;
  left: 0;
  font-size: 0.8em !important;
  z-index: 100000;
  animation: slideInRight 0.5s ease-in-out normal forwards;
}

.dynamic-mobile-toggle {
  position: absolute;
  top: 88px;
  right: 10px;
  font-size: 1em;
  color: var(--bg-secondary);
  background-color: var(--ash);
  border: 2px solid var(--bg-secondary);
  border-radius: 50%;
  padding: 8px 10px;
}

.dynamic-mobile-toggle.hidden {
  display: none;
}
@media screen and (max-width: 1500px) {
  .dynamic-container {
    font-size: 0.7em !important;
  }
}
