.schedule-view {
  color: var(--ash);
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  align-items: start;
  overflow-y: scroll;
}

.booking-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}

.booking-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  height: 100%;
}

.booking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--bg-secondary); /* White background for each item */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.booking-time,
.booking-client,
.booking-service {
  flex: 1;
  text-align: center;
}

.booking-time {
  font-weight: bold;
}

.booking-client {
  font-style: italic;
}

.booking-details {
  position: relative;
  top: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  animation: appear 0.5s ease-in-out;
}

.booking-details p {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  color: var(--black);
  font-family: Satisfy;
}

.booking-details span {
  font-family: var(--font-secondary);
  font-weight: bold;
}

/* Add more styles as needed */

/* MOBILE STYLING */

/* .schedule-view.mobile {
  position: absolute;
  top: 70px;
  left: 0;
  width: 98%;
  height: calc(100% - 80px) !important;
  margin: 0;
} */
