.calendar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  width: 70%;
  height: calc(var(--main-height) + 60px);
  padding-bottom: 1px;
  border-radius: 4px;
  font-size: 1em;
  margin: 0 1%;
  margin-top: -60px;
  transition: all 0.5s ease-in-out;
  /* animation: slideInBottom 0.5s ease-in-out normal forwards; */
}

.calendar-button {
  width: 100px;
  height: 30px;
  background-color: var(--bg-primary) !important;
  border-radius: 10px;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
}

.calendar-table {
  display: table;
  table-layout: fixed;
  border: 3px solid var(--ash);
  border-radius: 4px;
  margin-top: 5px;
  width: 100%;
  height: 100%;
}

.calendar-header {
  display: table-row;
  width: 100%;
  white-space: nowrap;
  height: calc(100% / 24);
  background-color: var(--bg-secondary);
  border: 3px solid var(--ash);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.calendar-row {
  display: table-row;
  width: 100%;
  white-space: nowrap;
  height: calc(100% / 24);
}

.header-days.Day .header-cell {
  width: 100%; /* Adjusted for the navigation buttons */
  text-align: center; /* Center the text */
}

.header-cell {
  text-align: center; /* Center the text */
  vertical-align: middle; /* Center the text */
  display: table-cell;
}

.header-cell.loading {
  color: var(--bg-secondary) !important;
}

.header-cell.today {
  background-color: var(--primary);
  color: var(--bg-primary);
}

.day.selected {
  background-color: #61dafb;
}

.scheduled-slot {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  pointer-events: none;
}

.navigation-button {
  border: none;
  background: var(--bg-secondary);
  cursor: pointer;
  color: var(--white);
  padding: 0;
  width: 50%;
  height: 100%;
  border-radius: 0px;
  border-right: 2px solid var(--bg-secondary);
  transition: all 0.1s ease-in-out;
}

.navigation-button:hover,
.navigation-button:focus {
  transform: scale(1) !important;
  background-color: var(--light-grey) !important;
}

.navigation-button:active {
  background-color: var(--bg-primary);
  animation: button-pulse 0.3s ease-in-out;
}

/* MOBILE STYLING */

.calendar.mobile {
  width: 95%;
  border-radius: 0;
  margin: 0 auto;
  align-items: flex-start;
}

.calendar.mobile .calendar-buttons {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  margin: 0px;
  position: absolute;
  bottom: 25%;
  left: 0px;
  gap: 0px;
}

.calendar.mobile .calendar-buttons > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 30px;
  width: 30px;
  padding: 15px;
  font-size: 1.2em;
}

.calendar.mobile .calendar-buttons > button > i {
  margin-right: 0px;
}

.calendar.mobile .calendar-buttons > span {
  translate: 0;
  font-size: 1.5em !important;
}

.calendar.mobile .calendar-buttons > h2 {
  font-size: 1.2em !important;
}

.calendar-mobile-expand {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: 90px;
  right: 10px;
  font-size: 1em;
  color: var(--ash);
  border: 2px solid var(--bg-secondary);
  border-radius: 4px;
}

/* @media screen and (max-width: 1500px) {
  .calendar-top-time {
    margin-top: 30px;
    font-size: 1em !important;
    text-align: center;
  }
  .calendar-top-title {
    font-size: 2em !important;
  }

  .timeframe-button {
    width: 20%;
    font-size: 1.8em;
  }
  .timeframe-button i {
    margin-right: 0px;
  }
  .timeframe-button h1 {
    display: none !important;
  }

  .calendar-buttons {
    gap: 5px;
    margin-right: 0px;
    width: 100px;
  }
} */

@media screen and (max-width: 768px) {
  /* .calendar-top {
    flex-direction: column;
    width: 100%;
    margin: 0px;
    min-height: 60px;
    gap: 2px;
  }

  .calendar-top-title {
    font-size: 1.5em !important;
  }

  .calendar-top-time {
    font-size: 1em !important;
    width: 90%;
    text-align: center;
    margin-top: 0px !important;
  } */
}
