.org-form {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  width: 100%;
  gap: 20px;
}

.org-form-span {
  display: flex;
  width: 100%;
  gap: 10px;
}

.org-form-sub {
  width: 100%;
}

.org-form-sub > span {
  font-size: 1.5rem;
  margin-top: 0px;
  color: var(--white);
}

.org-form-title {
  font-size: 2rem;
}

.org-form-button {
  text-transform: uppercase;
  background-color: var(--primary) !important;
  color: var(--bg-primary) !important;
}

.org-form-button:hover {
  background-color: var(--bg-primary) !important;
  color: var(--primary) !important;
}
