.cell {
  display: table-cell;
  border: 1px solid var(--bg-secondary);
  color: var(--primary);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  background-color: var(--bg-primary);
  text-align: center;
  vertical-align: middle;
  position: relative;
  transition: all 0s ease-in-out !important;
}

.cell:hover {
  border: 1px solid var(--primary);
}

.cell.closed {
  color: #000;
}

.calendar-row.closed {
  color: #000;
  height: 10px;
  display: none;
}

.calendar-row.open {
  display: table-row;
}

.calendar-row.closed .cell.hours {
  font-size: 0;
}

.expand-indicator {
  position: absolute;
  z-index: 100;
  cursor: -webkit-grab;
  animation: pulse 1s infinite;
  border-color: var(--ash) !important;
}

.expand-indicator.top {
  top: -20px; /* Adjust based on the cell's border and desired appearance */
  border-bottom: 15px solid white !important;
  border-color: var(--ash) !important;
  border-left: 15px solid transparent !important;
  border-right: 15px solid transparent !important;
}

.expand-indicator.bottom {
  border-top: 15px solid white !important;
  border-color: var(--ash) !important;
  border-left: 15px solid transparent !important;
  border-right: 15px solid transparent !important;

  bottom: -20px; /* Adjust based on the cell's border and desired appearance */
}

.cell.hours {
  display: table-cell;
  align-items: center;
  justify-content: center; /* Align hour text to the right */
  text-align: center; /* Align hour text to the right */
}

.cell.hours.Month {
  padding: 5px;
  min-width: 100px !important;
  font-size: 0.8em;
}

.cell.hours.Day {
  min-width: calc(30%) !important; /* Adjusted for 7 days + 1 hour column */
}

.cell.scheduled {
  color: white;
  font-weight: bold;
  font-family: var(--font-main);
}

.cell.booking {
  background-color: var(--secondary) !important;
  color: white !important;
  font-weight: bold !important;
  border: 1px solid var(--secondary) !important;
}

.cell.single-select {
  border: 1px solid white;
  background-color: var(--bg-secondary);
}

.cell.group-select {
  transition: all 0s ease-in-out !important;
}

.cell.group-select:not(.scheduled) {
  background-color: var(--primary) !important ;
  border: 1px solid transparent !important;
  color: var(--bg-primary);
}

.cell.group-select.booking {
  background-color: var(--secondary) !important;
  border: 1px solid black !important;
  color: var(--bg-primary);
}

.cell.group-select.start {
  border-top: 2px solid var(--bg-primary) !important;
  border-bottom: 0px solid var(--bg-primary) !important;
  border-left: 2px solid var(--bg-primary) !important;
  border-right: 2px solid var(--bg-primary) !important;
}

.cell.group-select.middle {
  border-left: 2px solid var(--bg-primary) !important;
  border-right: 2px solid var(--bg-primary) !important;
  border-top: 0px solid var(--bg-primary) !important;
  border-bottom: 0px solid var(--bg-primary) !important;
}

.cell.group-select.end {
  border-bottom: 2px solid var(--bg-primary) !important;
  border-left: 2px solid var(--bg-primary) !important;
  border-right: 2px solid var(--bg-primary) !important;
  border-top: 0px solid var(--bg-primary) !important;
}

.cell.group-select.booking.start {
  border-bottom: 1px solid transparent !important;
}

.cell.group-select.booking.middle {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

.cell.group-select.booking.end {
  border-top: 1px solid transparent !important;
}

.cell.over {
  background-color: var(--bg-secondary) !important;
}

.cell:not(.admin):not(.available):not(.hours) {
  pointer-events: none;
  background-color: var(--bg-secondary);
}
/* .cell.available.top {
  border-top: 1px solid var(--ash) !important;
  border-left: 1px solid var(--ash) !important;
  border-right: 1px solid var(--ash) !important;
}

.cell.available.middle {
  border-left: 1px solid var(--ash) !important;
  border-right: 1px solid var(--ash) !important;
}

.cell.available.bottom {
  border-bottom: 1px solid var(--ash) !important;
  border-left: 1px solid var(--ash) !important;
  border-right: 1px solid var(--ash) !important;
} */
