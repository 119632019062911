.dev-tools {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  max-height: 300px;
  padding: 10px;
  font-size: 14px;
  width: 100vw;
  overflow-x: hidden !important;
}

.dev-tools.collapsed {
  overflow: hidden;
  width: 50px;
  background: none;
}

.dev-tools.collapsed button i {
  font-size: 2em;
}

.dev-tools-main > button {
  background: var(--bg-secondary) !important;
  width: 100px !important;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0px 10px;
}

.dev-tools-main > button:hover {
  background: #444 !important;
}

.dev-tools h4 {
  display: flex;
}

.dev-tools h4 button {
  background: #026fcf;
  color: #fff;
  border: none;
  padding: 3px 10px;
  margin-bottom: 5px;
  cursor: pointer;
  width: fit-content;
  margin-left: 10px;
}

.dev-tools button {
  background: var(--bg-secondary);
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-bottom: 5px;
  cursor: pointer;
  width: 100%;
}

.dev-tools button:hover {
  background: #444;
  scale: none;
}

.dev-tools-pretty {
  display: flex;
  margin-left: 50px;
  white-space: none;
}

.dev-tools-pretty pre {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
