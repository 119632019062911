.landing-page {
  font-family: var(--font-main);
  color: var(--text-white);
  background-color: var(--bg-primary);
  width: 100%;
  min-height: var(--main-height);
  margin: 0 auto;
  z-index: 1000;
}

.landing-page > img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.hero {
  text-align: center;
  justify-content: center;
  padding: 0px 20px;
  padding-bottom: 30px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  font-size: 1.7em;
  translate: -5% 0;
  position: relative;
  z-index: 2;
  animation: slideInLeft 0.5s ease-in-out normal forwards;
}

.hero > span {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10%;
}

.landing-clock-container {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
}
.landing-clock {
  width: 350px;
  aspect-ratio: 1/1 !important;
}

.hero h1 {
  font: 1.2em var(--font-secondary);
  margin-block-start: 20px !important;
  margin-block-end: 0px !important;
}

.hero .timeslot-title {
  font: 1.8em var(--font-secondary);
}

.hero p {
  font: 1em var(--font-main);
  margin-bottom: 30px;
}

.hero-button {
  background-color: var(--secondary);
  color: var(--bg-primary) !important;
  border: 1px solid var(--secondary);
  cursor: pointer;
  width: 60%;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 0.7em;
  margin: 0 auto;
}

.hero-button:hover {
  background-color: var(--bg-primary) !important;
  color: var(--secondary) !important;
}

.features {
  position: relative;
  padding: 20px 20px;
  background-color: var(--primary);
  color: var(--bg-primary);
  z-index: 1;
  animation: slideInRight 0.5s ease-in-out normal forwards;
}

.features h2,
.testimonials h2 {
  text-align: center;
  font: 2em var(--font-secondary);
  margin-bottom: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  position: relative;
  z-index: 2 !important;
}

.feature-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 50%;
  margin: 0 auto;
}

.feature {
  width: 30%;
  min-width: 200px;
  text-align: center;
}

.feature h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  gap: 10px;
}

.features h3 > i {
}

.testimonials {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: var(--bg-primary);
  animation: slideInLeft 0.5s ease-in-out normal forwards;
}

.testimonial {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  z-index: 2 !important;
}

.slideInUp {
  animation: slideInTop 0.5s ease-in-out normal forwards;
}

.slideOutDown {
  animation: slideOutTop 0.5s ease-in-out normal forwards;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  .hero > span {
    width: 90%;
    margin: 0 auto;
  }

  .landing-clock-container {
    width: 100%;
  }

  .landing-clock {
    width: 100%;
  }

  .feature-list {
    width: 100%;
  }

  .feature {
    width: 100%;
  }
}
