.appointments-section {
  background-color: var(--bg-primary);
  color: var(--text-white);
  display: flex;
  flex-direction: column;
  font-size: 2em;
  margin: 20px 10px;
}
.user-info h2,
.appointments-section h3 {
  color: var(--primary);
  font-size: 1em;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.user-info p,
.appointments-section p {
  margin: 5px 0;
}

.appointments-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointments-title i {
  cursor: pointer;
  border: 2px solid;
  border-radius: 50%;
  padding: 9px 10px;
}

.appointments-title .fa-check {
  color: var(--success);
}

.appointments-title .fa-x {
  color: var(--danger);
}

.appointments-title .fa-hourglass {
  color: var(--warning);
}

.appointments-title span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.5em;
}

.appointments-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  width: 100%;
  height: auto;
  list-style-type: none;
  padding: 0;
  padding-right: 10px;
  overflow-y: scroll;
  max-height: 75vh;
  margin-block-start: 0;
  margin-block-end: 0;
}

.appointment-item {
  flex: 1 1 100%;
  background-color: var(--bg-primary);
  border: 3px solid var(--bg-secondary) !important;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.appointment-item.confirmed {
  border-color: var(--success);
  color: var(--success);
}

.appointment-item.pending {
  border-color: var(--warning);
  color: var(--warning);
}

.appointment-item.cancelled {
  border-color: var(--danger);
  color: var(--danger);
}

.appointment-details .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid;
  font-size: 0.9em;
}
.appointment-details h4 {
  margin: 0 0 10px 0;
}

.appointment-date-time,
.appointment-client,
.appointment-status,
.appointment-price {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0px !important;
  font-size: 0.7em;
}

.appointment-date-time,
.appointment-price {
  color: var(--ash);
}

.appointment-price {
  font-weight: bold;
}

.no-appointments {
  text-align: center;
  margin-top: 20px;
  color: var(--light-grey);
}

.appointment-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.appointment-buttons button {
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--text-white);
  background-color: var(--bg-primary);
}

.cancel-appointment {
  border-color: var(--danger) !important;
  color: var(--danger) !important;
}
.edit-appointment {
  border-color: var(--warning) !important;
  color: var(--warning) !important;
}

.edit-appointment:hover {
  transform: translateY(-3px);
  background-color: var(--warning);
  opacity: 0.8;
}

.cancel-appointment:hover {
  transform: translateY(-3px);
  background-color: var(--danger);
  opacity: 0.8;
}
