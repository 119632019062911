@import url(https://fonts.googleapis.com/css?family=Satisfy);

.yellow {
  background: #eae672;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
}

.pin {
  background-color: #aaa;
  display: block;
  height: 20px;
  width: 2px;
  position: absolute;
  left: 50%;
  top: -16px;
  z-index: 1;
}

.pin:after {
  background-color: var(--red);
  background-image: radial-gradient(
    25% 25%,
    circle,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 0%, 0.3)
  );
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    inset 3px 3px 3px hsla(0, 0%, 100%, 0.2),
    inset -3px -3px 3px hsla(0, 0%, 0%, 0.2),
    23px 20px 3px hsla(0, 0%, 0%, 0.15);
  content: "";
  height: 12px;
  left: -5px;
  position: absolute;
  top: -10px;
  width: 12px;
}

.pin:before {
  background-color: hsla(0, 0%, 0%, 0.1);
  box-shadow: 0 0 0.25em hsla(0, 0%, 0%, 0.1);
  content: "";

  height: 24px;
  width: 2px;
  left: 0;
  position: absolute;
  top: 8px;

  transform: rotate(57.5deg);
  -moz-transform: rotate(57.5deg);
  -webkit-transform: rotate(57.5deg);
  -o-transform: rotate(57.5deg);
  -ms-transform: rotate(57.5deg);

  transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    transform: translateY(-10%);
  }
  100% {
    height: 100%;
    opacity: 1;
    transform: translateY(0);
  }
}

/* Keyframes for typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Keyframes for blinking cursor */
@keyframes blink-caret {
  from,
  to {
    border-color: inherit;
  }
  50% {
    border-color: transparent;
  }
}

@keyframes slideInTop {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.flip-left {
  animation: flipPageLeft 0.6s ease-in-out;
  transform-style: preserve-3d; /* Preserve 3D transformations */
  z-index: 2; /* Ensure current page is above the next page */
}

.flip-right {
  animation: flipPageRight 0.6s ease-in-out;
  transform-style: preserve-3d; /* Preserve 3D transformations */
  z-index: 2; /* Ensure current page is above the next page */
}

@keyframes flipPageRight {
  0% {
    transform: rotateY(0deg);
    transform-origin: left;
    opacity: 1;
  }
  100% {
    transform: rotateY(-180deg);
    scale: 0.1;
    transform-origin: left;
    opacity: 0;
  }
}

@keyframes flipPageLeft {
  0% {
    transform: rotateY(-180deg);
    scale: 0.1;
    transform-origin: left;
    opacity: 1;
  }
  100% {
    transform: rotateY(0deg);
    transform-origin: left;
    opacity: 0.3;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes width-grow-90 {
  0% {
    width: 30%;
  }
  100% {
    width: 90%;
  }
}

@keyframes width-shrink-30 {
  0% {
    width: 90%;
  }
  100% {
    width: 30%;
  }
}

@keyframes width-grow-60 {
  0% {
    font-size: 0px;
    width: 0%;
  }
  100% {
    width: 60%;
  }
}

@keyframes width-shrink-0 {
  0% {
    width: 60%;
  }
  100% {
    width: 0%;
  }
}
