/* CARD STYLING */
.user-profile {
  background-color: var(--bg-primary);
  color: var(--text-white);
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  scroll-behavior: smooth;
}

.user-profile html {
  scroll-behavior: smooth;
}

.card-background {
  width: 100% !important;
  height: 60% !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
}

.card {
  width: 100%;
}
.card:before {
  content: "";
  display: block;
  width: 100%;
  height: 600px;
  filter: saturate(35%);
  background-image: url("https://www.phdmedia.com/latam/wp-content/uploads/sites/85/2017/06/Banner-2.gif");
  background-size: cover;
}

.card-header {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -80px;
}

.card-main {
  display: flex;
  margin: 0 auto;
  width: 100%;
  gap: 30px;
  flex-direction: row;
  position: relative;
  top: -50px;
  justify-content: center;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (max-width: 600px) {
  .card-title {
    width: 100%;
    font-size: 0.5em;
  }

  .card-header {
    top: -38px;
  }
}

.card img {
  position: relative;
  border-radius: 50%;
}

.heading-box {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -20px;
}

.heading-box h1 {
  font-size: 3em;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  color: var(--ash);
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.heading-box h3 {
  font-weight: 200;
  margin: 0;
  color: var(--primary-dark);
}

.activity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 30px;
  text-align: center;
  border: 2px solid var(--primary-dark);
  border-radius: 4px;
  margin: 5px;
  overflow: hidden;
  white-space: nowrap;
  gap: 10px;
  width: 20% !important;
  min-width: fit-content;
  transition: all 0.2s ease-in-out;
}
.activity * {
  display: block;
}
@media (min-width: 601px) {
  .activity {
    flex-direction: row;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
  }
  .activity * {
    display: inline;
    margin: 0 auto;
  }
}

.activity:hover {
  background-color: var(--bg-secondary);
  border: 2px solid var(--primary);
  color: var(--primary);
  cursor: pointer;
}
.activity .activity-name {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary);
}
@media (min-width: 601px) {
  .activity .activity-name {
    margin: 0;
  }
}
.activity .index {
  width: fit-content;
}
.activity i,
.activity span {
  font-size: 1em;
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .activity {
    flex-direction: row;
    width: 95% !important;
    margin: 0 auto;
    padding: 0;
    font-size: 0.8em;
    justify-content: space-between;
  }

  .activity > i {
    width: 10%;
  }

  .activity .activity-name {
    width: 30%;
  }

  .activity .index {
    width: 70%;
  }

  .card-background {
    height: 100% !important;
  }

  .card-main {
    width: 100%;
    flex-direction: column;
    margin-top: 10%;
  }

  .card img {
    bottom: 10px;
  }

  .card:before {
    height: 400px;
  }

  .appointments-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
  }

  .appointments-list {
    margin-block-start: 0px;
  }

  .appointments-title {
    margin-top: 0px !important;
    font-size: 1em !important;
  }
}
