.sidebar {
  width: var(--sidebar-width);
  height: 100vh; /* Full height */
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--bg-secondary); /* Dark background */
  animation: slideInLeft 0.5s ease-in-out normal forwards;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 6vh;
}

.sidebar.open {
}

.sidebar.closed {
}

.sidebar-add-form {
  gap: 20px;
}

.sidebar-title-header {
  position: relative;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0px;
  font-size: 1.2em;
  font-family: var(--font-main);
  height: 80px;
}

.sidebar-title-header h1 > span {
  color: var(--secondary);
}

.sidebar-title-header img {
  width: 60px;
  height: 60px;
  margin-right: -5px;
}

.sidebar-content {
  border: 2px solid var(--primary);
  border-radius: 4px;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 5%;
  background-color: var(--bg-primary);
}

.sidebar-content > h1 {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  background-color: var(--bg-secondary);
  padding: 10px;
  color: var(--primary);
}

.sidebar-item {
  width: 90%;
  text-align: left;
  border: 2px solid var(--ash); /* Lighter line for each item */
  border-radius: 4px;
  padding: 5px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 10px auto;
  font-size: 1.3em;
  color: var(--ash);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.sidebar-item.selected,
.sidebar-item:hover {
  background-color: var(--ash);
  color: var(--bg-primary);
}

.sidebar-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 0.6em sans-serif;
  width: 90%;
  margin: 0 auto;
  height: 50px;
}

.sidebar-item-header > h1 {
  font-size: 1.8em;
}

.sidebar-item-header > h1 > i {
  margin-right: 10px;
}

.sidebar-item-header > i {
  position: relative;
  font-size: 2.5em;
  transition: scale 0.3s ease-in-out;
  left: 10px;
  padding: 5px;
  z-index: 1;
}

.sidebar-item-button {
  position: relative;
  font-size: 1em;
  width: 95%;
  padding: 5px;
  z-index: 1;
  background-color: var(--bg-primary);
  color: var(--ash);
  border-radius: 2px;
  letter-spacing: 1px;
}

.sidebar-item-button:hover {
  background-color: var(--bg-primary);
  color: var(--ash);
  letter-spacing: 2px;
}

.sidebar-item-header > i:hover {
  transform: scale(1.1);
}

.sidebar-item-body {
  width: 80%;
  color: var(--bg-secondary);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  animation: slide-down 0.3s ease-in-out normal forwards;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.sidebar-item-body li {
  padding: 5px;
}

.sidebar-item-body.none {
  display: none;
  height: 0px;
}

.sidebar-item.add {
  border: 2px dashed var(--light-grey) !important;
  margin-top: 20px;
  color: var(--light-grey);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-item.add:hover {
  border: 2px dashed var(--ash) !important;
  color: var(--ash);
  background-color: var(--bg-primary);
}

.sidebar-item.add h1 {
  font-size: 1em;
  text-align: center;
}

.sidebar-item.add h1 i {
  margin-right: 10px;
}

.sidebar-employee-clock {
  width: 90%;
  aspect-ratio: 1/2;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ash);
}

.sidebar-employee-item {
  text-align: left;
  border-radius: 4px;
  padding: 5px 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 10px auto;
  font-size: 1.2em;
  color: var(--ash);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.sidebar-employee-item .title {
  font-size: 1.5em;
  color: var(--primary);
  margin-top: -10px;
}

.sidebar-content.employee {
  height: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-employee-item hr {
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* MOBILE STYLING */

.sidebar-mobile-toggle {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 2em;
  color: var(--ash);
  z-index: 10000;
  opacity: 1 !important;
  cursor: pointer;
}

.sidebar-mobile-toggle:hover {
  color: var(--primary);
}
.sidebar-mobile-toggle.fa-caret-left {
  left: 280px;
  animation: slideInLeft 0.5s ease-in-out normal forwards;
}
.sidebar-mobile-toggle.fa-caret-right {
  left: 10px;
  transition: all 0.3s ease-in-out;
}

.sidebar-mobile-toggle.fa-caret-left.mobile {
  left: 95%;
}

.sidebar-theme-switch {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--white);
  margin: 0 auto;
  bottom: 0px;
  z-index: 100;
  border-bottom: 3px solid var(--primary);
  padding-bottom: 20px;
  width: 80%;
}

@media (max-width: 1045px) {
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--bg-secondary);
    animation: slideInLeft 0.5s ease-in-out normal forwards;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
