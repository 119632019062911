.navbar {
  background-color: var(--bg-primary);
  color: var(--text-white);
  width: calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  height: var(--nav-height);
  display: flex;
}

.navbar.title {
  justify-content: flex-start;
  margin-left: 20px;
  width: 100%;
}

.navbar-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 70%;
  padding-left: 45px;
  padding-top: 5px;
}

.navbar-header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.navbar-header > h1 {
  display: flex;
  font-weight: 800;
  font-size: 2.4em;
  margin-left: -5px;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  right: 1%;
  gap: 40px;
  width: 30%;
}

.navbar-content i {
  font-size: 2em;
  transition: all 0.3s ease-in-out;
}

.navbar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
}

.navbar-item > i {
  color: var(--ash);
  transition: all 0.3s ease-in-out;
}

.navbar-item > i:hover {
  scale: 1.1;
}

.navbar-item.profile-item {
  font-size: 1em !important;
  height: 54px !important;
  width: 54px !important;
}

.navbar-item.profile-item .fa-user {
  color: var(--primary);
  transition: all 0.3s ease-in-out;
  margin-left: 8px;
  font-size: 2.5em;
}

.navbar-item.current > i {
  color: var(--primary) !important;
  scale: 1.1 !important;
}

.navbar-item.current:hover > i {
  scale: 1 !important;
}

.profile-pic {
  height: 40px !important;
  border-radius: 50%;
  border: 2px solid var(--primary);
  padding: 5px;
}

.profile-pic:hover {
  scale: 1.1;
}

.admin-navbar {
  display: flex;
}

.loggedin-navbar {
  display: flex;
}

.flipped {
  transform: rotateY(180deg);
}

/* MOBILE STYLING  */
@media screen and (max-width: 1500px) {
  .navbar-content {
    gap: 20px;
  }
}
@media screen and (max-width: 768px) {
  .navbar-content {
    font-size: 0.8em;
    gap: 15px;
    width: 50%;
    justify-content: flex-end;
  }

  .navbar-header {
    width: 50%;
    padding-left: 10px;
  }

  .navbar-item.profile-item {
    margin-right: 5px;
  }
}
