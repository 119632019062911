/* FILEPATH: /c:/Users/CharllieDhaldane/Desktop/Work/portfolio/Scheduler.io/client/src/Components/BookingPage/BookingPage.css */

.booking-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: slideUp 0.5s ease-in-out forwards;
  width: 100%;
  min-height: calc(var(--main-height) - 40px);
  padding: 20px;
  transition: all 0.3s ease-in-out;
}

.book-info {
  width: 50%;
  font-size: 24px;
  text-align: center;
  color: var(--white);
  gap: 10px;
  height: 95%;
}

.book-info a {
  font-size: 1.5em;
  text-decoration: none;
}

.book-info h1 {
  margin: 10px;
}

.book-info-display {
  font-size: 1em;
  border-radius: 10px;
  background-color: #eaeef2;
  color: #0e0d0d;
  padding: 10px;
  margin: 10px;
}
.book-info-display p {
  margin: 0px;
}
.book-info-display var {
  font-size: 1.2em;
  color: var(--blue);
  font-style: normal;
}
.book-info > button {
  margin-top: 20px;
  width: 50%;
}
.book-info > button,
.book-form > button {
  height: 60px;
  background-color: var(--primary);
  color: var(--bg-primary);
}

.book-info > button:hover,
.book-form > button:hover {
  background-color: var(--primary-dark);
}

.book-form {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 20px;
}

.book-form h1 {
  margin: 0px;
  color: var(--primary);
}

.book-form > span {
  display: flex;
  gap: 10px;
}

.book-input {
  font-size: 1.5em;
}

.book-input > input,
.book-input > textarea {
  background-color: var(--bg-secondary) !important;
}

.book-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  animation: appear 0.5s ease-in-out forwards;
  margin-bottom: 20px;
  color: var(--white);
}

.book-message p {
  margin: 0px;
}

@keyframes slideUp {
  0% {
    background-color: var(--bg-secondary);
    transform: translateY(100%);
  }
  100% {
    background-color: var(--bg-primary);
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .booking-container {
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
  }
  .book-info {
    width: 100%;
  }
  .book-info > button {
    width: 100%;
  }
}
